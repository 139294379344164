import { combineReducers } from 'redux';

import pharmaCoReducer from './pharmaCo';
import cmoReducer from './cmo';
import commonReducer from './common';
import p2pReducer from './procurementPlan';

export default combineReducers({
  pharmaCo: pharmaCoReducer,
  cmo: cmoReducer,
  common: commonReducer,
  p2p: p2pReducer,
});
