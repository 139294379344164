import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  styledLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
});

const CircularLoading = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.styledLoader} {...props}>
      <CircularProgress color='primary' thickness={7} />
      {children}
    </div>
  );
};

export default CircularLoading;
