import { combineReducers } from 'redux';

import dashboardReducer from './dashboardReducer';
import filesReducer from './filesReducer';
import reportsReducer from './reportsReducer';
import accessControlReducer from './accessControl';
import saslReducer from './saslReducer'

export default combineReducers({
  dashboard: dashboardReducer,
  reports: reportsReducer,
  files: filesReducer,
  accessControl: accessControlReducer,
  sasl: saslReducer
});