import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import CircularProgress from '@material-ui/core/CircularProgress';
import CircularLoading from './components/common/models/CircularLoading';

// import PharmaCoRoutes from './components/pharmaCo/PharmaCoRoutes';
// import CmoRoutes from './components/cmo/CmoRoutes';

import NotFound from '../errorPage404';
import IdleTimer from 'react-idle-timer';
import { sessionTimeoutSettings } from '../../containers/actions/userActions';
import { userLogout } from '../../containers/actions/userActions';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import { getScreensAccess } from './containers/actions/common/accessControl';
// import UserAccess from './UserAccess';
import { viewApplicationSettings } from './containers/actions/common/dashboardAction';

// const PharmaCoRoutes = lazy(() =>
//   import('./components/pharmaCo/PharmaCoRoutes')
// );
// const CmoRoutes = lazy(() => import('./components/cmo/CmoRoutes'));
const UserAccess = lazy(() => import('./UserAccess'));
const P2pRoutes = lazy(() => import('./components/procurementPlan/P2pRoutes'));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

class PcmpRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      signoutTime: 1000 * 60 * 15,
      warningTime: 1000 * 60 * 14,
      isPasswordRequired: true,
      isLogoutForced: false,
      isTimedOut: false,
    };
    this.idleTimer = null;
  }

  componentDidMount() {
    if (!this.props.screensAccess.status) {
      //get screens access
      this.props.getScreensAccess();
    }
    if (!this.props.session.data) {
      // get session settings
      this.props.sessionTimeoutSettings();
    }
    if (this.props.screensAccess.status && this.props.session.data) {
      // stop loading after we get both session and screen data
      this.setState({ loading: false });
    }
    //to view date settings
    let data = {
      ...this.props.match.params,
      collabType: localStorage.collabType,
    };
    this.props.viewApplicationSettings(data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.session !== prevProps.session &&
      this.props.session.status === true
    ) {
      this.setState({
        signoutTime: this.props.session.data.sessionInActivityTime * 1000,
        warningTime:
          this.props.session.data.sessionInActivityTime * 1000 - 3000,

        isPasswordRequired:
          this.props.session.data.ispasswordRequiredAfterLockout,
        isLogoutForced: this.props.session.data.isLogoutForcedAfterInactivity,
        // loading: false,
      });
    }
    if (
      this.props.screensAccess !== prevProps.screensAccess &&
      this.props.screensAccess.status === true
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  handleOnAction = (event) => {
    console.log('user did something', event);
  };

  handleOnActive = (event) => {
    console.log('user is active', event);
    console.log('time remaining', this.idleTimer.getRemainingTime());
  };

  handleOnIdle = (event) => {
    // console.log('user is idle', event);
    // console.log('last active', this.idleTimer.getLastActiveTime());
    // this.setState({ isTimedOut: true });
    // setTimeout(() => this.inactiveUserAction(), 2000);
    const { isPasswordRequired } = this.state;

    if (isPasswordRequired === true) {
      this.props.history.push('/sessiontimeout');
    } else {
      this.props.userLogout(this.props.history);
    }
  };
  inactiveUserAction = () => {
    const { isPasswordRequired } = this.state;

    if (isPasswordRequired === true) {
      this.props.history.push('/sessiontimeout');
    } else {
      this.props.userLogout(this.props.history);
    }
  };
  render() {
    const { loading, signoutTime, isTimedOut } = this.state;

    return (
      <React.Fragment>
        {loading === true ? (
          <CircularLoading />
        ) : (
          <React.Fragment>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={signoutTime}
              onIdle={this.handleOnIdle}
              debounce={3000}
              stopOnIdle
            />
            {/* your app here */}
            <Dialog
              open={isTimedOut}
              TransitionComponent={Transition}
              onClose={this.handleClose}
            >
              <DialogContent>
                You will be automatically Logged Out in a while due to
                inactivity
              </DialogContent>
            </Dialog>
            <Suspense fallback={<CircularLoading />}>
              <Switch>
                {/* <Route
                  name='Pharma Co Routes'
                  path='/pcmp/Pharma Co'
                  component={PharmaCoRoutes}
                />
                <Route
                  name='CMO Routes'
                  path='/pcmp/cmo'
                  component={CmoRoutes}
                /> */}
                <Route
                  name='Redirecting Route'
                  path='/pcmp/mosymphony/useraccess'
                  component={UserAccess}
                />
                <Route
                  name='Redirecting Route'
                  path='/pcmp/:collabType'
                  component={P2pRoutes}
                />

                <Route name='404 Not Found' component={NotFound} />
              </Switch>
            </Suspense>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.user.session,
  screensAccess: state.pcmp.common.accessControl.screensAccess,
});

export default connect(mapStateToProps, {
  sessionTimeoutSettings,
  userLogout,
  getScreensAccess,
  viewApplicationSettings,
})(withRouter(PcmpRoutes));
