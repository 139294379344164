import {
  UPLOAD_PLANFROMFILE,
  UPDATE_PLANFROMFILE,
  ALL_CMO_MEMBERS,
  PLAN_SUMMARY,
  PLAN_HEADER_DATA,
  PLAN_LOADING,
  DESTROY_PLAN_LOADING,
  OPEN_MAIN_TABLE_LOADING,
  CLOSE_MAIN_TABLE_LOADING,
  UPLOAD_COST_SHEET,
  UPDATE_COST_SHEET,
  FETCH_COST_SHEET,
  OPEN_COST_SHEET_LOADING,
  CLOSE_COST_SHEET_LOADING,
  OPEN_MATERIAL_LOAD,
  CLOSE_MATERIAL_LOAD,
  MATERIAL_API_CALL,
  OPEN_EXCIPIENT_LOADING,
  CLOSE_EXCIPIENT_LOADING,
  MATERIAL_EXCIPIENT_CALL,
  OPEN_PM_LOADING,
  CLOSE_PM_LOADING,
  PM_CALL,
  UPDATE_API_FG_MATERIAL_DETAILS,
  UPDATE_EXCIPIENTS_MATERIAL_DETAILS,
  UPDATE_PM_DETAILS,
  UPDATE_FG_MATERIAL_STATUS,
  UPDATE_EXCIPIENTS_FG_MATERIAL_STATUS,
  UPDATE_PM_FG_MATERIAL_STATUS,
  DATE_VALIDITIES,
  DATE_COST_PACK,
  APPROVE_RATES,
  UPDATE_PLAN_QTY,
  COST_SHEET_VERSIONS,
  FETCH_CS_REMARKS,
  ADD_CS_REMARKS,
  FETCH_CS_REMARKS_REPLIES,
  UPDATE_CS_REMARKS,
  FETCH_ALL_PLAN_YEARS,
  FETCH_ALL_PLAN_DETAILS,
  FETCH_PLAN_REMARKS,
  ADD_PLAN_REMARKS,
  FETCH_PLAN_REMARKS_REPLIES,
  UPDATE_PLAN_REMARKS,
  PLAN_DETAILS_LOAD,
  CLEAR_PLAN_TABLE,
  OPEN_PLAN_DETAILS_LOAD,
  USERNAME_LIST,
  CLEAR_PLAN_REMARKS,
  CLEAR_CS_REMARKS,
  ADD_DELETE_PRODUCTS_FROM_RATE_AUTO_POPULATION,
} from '../../actions/types';

const initialState = {
  uploadPlanFile: {},
  updatePlanFile: {},
  cmoMembersList: [],
  planSummary: {},
  planHeader: [],
  planLoading: true,
  mainTableLoading: true,
  uploadCostSheetFile: {},
  updateCostSheetFile: {},
  fetchCostSheetDetails: {},
  costSheetLoading: true,
  materialLoad: true,
  materialAPI: [],
  materialExcipientLoad: true,
  materialExcipient: [],
  pmLoad: true,
  packagingMaterial: [],
  updateAPIfg: {},
  updateExcipientfg: {},
  updatePMfg: {},
  updateFgMaterialStatus: {},
  updateExcipientsFgMaterialStatus: {},
  updatePmFgMaterialStatus: {},
  dateValidity: {},
  costPerPackDate: {},
  approveRates: {},
  updatePlanQty: {},
  costSheetVersions: {},
  csRemarks: {
    pages: 1,
    csRemarksArray: [],
    loading: true,
  },
  addCSRemarks: {},
  CSRemarksReplies: {},
  updateCSRemarks: {},
  fetchAllPlanYears: [],
  allPlanDetails: {
    planArray: [],
    pages: 1,
  },
  planRemarks: {
    pages: 1,
    planRemarksArray: [],
    loading: true,
  },
  addPlanRemarks: {},
  planRemarksReplies: {},
  updatePlanRemarks: {},
  planDetailsTableLoad: true,
  filters: {
    pageNumber: 1,
    pageSize: 5,
    vendorName:
      localStorage.collabType === 'CMO' ? localStorage.organizationName : 'all',
    searchParameter: '',
    status: 'all',
  },
  fileDownload: {},
  usersList: [],
  feedBack: {
    open: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_PLANFROMFILE:
      return {
        ...state,
        uploadPlanFile: action.payload,
      };
    case UPDATE_PLANFROMFILE:
      return {
        ...state,
        updatePlanFile: action.payload,
      };
    case ALL_CMO_MEMBERS:
      return {
        ...state,
        cmoMembersList: action.payload,
      };

    case PLAN_SUMMARY:
      return {
        ...state,
        planSummary: {
          ...state.planSummary,
          [action.payload.schrockenProductID]: action.payload.data,
        },
      };

    case PLAN_HEADER_DATA:
      return {
        ...state,
        planHeader: action.payload,
      };

    case PLAN_LOADING:
      return {
        ...state,
        planLoading: true,
      };

    case DESTROY_PLAN_LOADING:
      return {
        ...state,
        planLoading: false,
      };

    case OPEN_MAIN_TABLE_LOADING:
      return {
        ...state,
        mainTableLoading: true,
      };

    case CLOSE_MAIN_TABLE_LOADING:
      return {
        ...state,
        mainTableLoading: false,
      };

    case UPLOAD_COST_SHEET:
      return {
        ...state,
        uploadCostSheetFile: action.payload,
      };

    case UPDATE_COST_SHEET:
      return {
        ...state,
        updateCostSheetFile: action.payload,
      };

    case FETCH_COST_SHEET:
      return {
        ...state,
        fetchCostSheetDetails: action.payload,
      };

    case OPEN_COST_SHEET_LOADING:
      return {
        ...state,
        costSheetLoading: true,
      };
    case CLOSE_COST_SHEET_LOADING:
      return {
        ...state,
        costSheetLoading: false,
      };

    case OPEN_MATERIAL_LOAD:
      return {
        ...state,
        materialLoad: true,
      };
    case CLOSE_MATERIAL_LOAD:
      return {
        ...state,
        materialLoad: false,
      };

    case MATERIAL_API_CALL:
      return {
        ...state,
        materialAPI: action.payload,
      };

    case OPEN_EXCIPIENT_LOADING:
      return {
        ...state,
        materialExcipientLoad: true,
      };
    case CLOSE_EXCIPIENT_LOADING:
      return {
        ...state,
        materialExcipientLoad: false,
      };

    case MATERIAL_EXCIPIENT_CALL:
      return {
        ...state,
        materialExcipient: action.payload,
      };

    case OPEN_PM_LOADING:
      return {
        ...state,
        pmLoad: true,
      };
    case CLOSE_PM_LOADING:
      return {
        ...state,
        pmLoad: false,
      };

    case PM_CALL:
      return {
        ...state,
        packagingMaterial: action.payload,
      };

    case UPDATE_API_FG_MATERIAL_DETAILS:
      return {
        ...state,
        updateAPIfg: action.payload,
      };

    case UPDATE_EXCIPIENTS_MATERIAL_DETAILS:
      return {
        ...state,
        updateExcipientfg: action.payload,
      };

    case UPDATE_PM_DETAILS:
      return {
        ...state,
        updatePMfg: action.payload,
      };
    case UPDATE_FG_MATERIAL_STATUS:
      return {
        ...state,
        updateFgMaterialStatus: action.payload,
      };
    case UPDATE_EXCIPIENTS_FG_MATERIAL_STATUS:
      return {
        ...state,
        updateExcipientsFgMaterialStatus: action.payload,
      };
    case UPDATE_PM_FG_MATERIAL_STATUS:
      return {
        ...state,
        updatePmFgMaterialStatus: action.payload,
      };
    case DATE_VALIDITIES:
      return {
        ...state,
        dateValidity: action.payload,
      };

    case DATE_COST_PACK:
      return {
        ...state,
        costPerPackDate: action.payload,
      };

    case APPROVE_RATES:
      return {
        ...state,
        approveRates: action.payload,
      };

    case UPDATE_PLAN_QTY:
      return {
        ...state,
        updatePlanQty: action.payload,
      };

    case COST_SHEET_VERSIONS:
      return {
        ...state,
        costSheetVersions: action.payload,
      };
    case FETCH_CS_REMARKS:
      // clearExistingRemarks indicates that prev Remarks can be cleared
      if (action.payload.clearExistingRemarks) {
        return {
          ...state,
          csRemarks: {
            pages: action.payload.pages,
            csRemarksArray: action.payload.remarks,
            loading: action.payload.loading,
          },
        };
      } else {
        return {
          ...state,
          csRemarks: {
            pages: action.payload.pages,
            csRemarksArray: [
              ...state.csRemarks.csRemarksArray,
              ...action.payload.remarks,
            ],
            loading: action.payload.loading,
          },
        };
      }
    case ADD_CS_REMARKS:
      return {
        ...state,
        addCSRemarks: action.payload,
      };
    case FETCH_CS_REMARKS_REPLIES:
      return {
        ...state,
        CSRemarksReplies: {
          ...state.CSRemarksReplies,
          [action.payload.schrockenCSRemarkID]: action.payload.data,
          firstRemarkUpdatedDate: action.payload.firstRemarkUpdatedDate,
        },
      };
    case UPDATE_CS_REMARKS:
      return {
        ...state,
        updateCSRemarks: action.payload,
      };
    case FETCH_ALL_PLAN_YEARS:
      return {
        ...state,
        fetchAllPlanYears: action.payload,
      };
    case FETCH_ALL_PLAN_DETAILS:
      return {
        ...state,
        allPlanDetails: {
          pages: action.payload.pages,
          planArray: [
            ...state.allPlanDetails.planArray,
            ...action.payload.planArray,
          ],
        },
      };

    case CLEAR_PLAN_TABLE:
      return {
        ...state,
        allPlanDetails: {
          pages: 1,
          planArray: [],
        },
      };

    case FETCH_PLAN_REMARKS:
      // clearExistingRemarks indicates that prev Remarks can be cleared
      if (action.payload.clearExistingRemarks) {
        return {
          ...state,
          planRemarks: {
            pages: action.payload.pages,
            planRemarksArray: action.payload.remarks,
            loading: action.payload.loading,
          },
        };
      } else {
        return {
          ...state,
          planRemarks: {
            pages: action.payload.pages,
            planRemarksArray: [
              ...state.planRemarks.planRemarksArray,
              ...action.payload.remarks,
            ],
            loading: action.payload.loading,
          },
        };
      }
    case ADD_PLAN_REMARKS:
      return {
        ...state,
        addPlanRemarks: action.payload,
      };
    case FETCH_PLAN_REMARKS_REPLIES:
      return {
        ...state,
        planRemarksReplies: {
          ...state.planRemarksReplies,
          [action.payload.schrockenPlanRemarkID]: action.payload.data,
          firstRemarkUpdatedDate: action.payload.firstRemarkUpdatedDate,
        },
      };
    case UPDATE_PLAN_REMARKS:
      return {
        ...state,
        updatePlanRemarks: action.payload,
      };
    case PLAN_DETAILS_LOAD:
      return {
        ...state,
        planDetailsTableLoad: false,
      };
    case OPEN_PLAN_DETAILS_LOAD:
      return {
        ...state,
        planDetailsTableLoad: true,
      };
    // new table approach
    case 'CHANGE_PAGE':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageNumber: action.payload,
        },
      };
    case 'CHANGE_PAGE_SIZE':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageSize: action.payload,
        },
      };
    case 'SEARCH':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageNumber: 1,
          searchParameter: action.payload,
        },
      };
    case 'SELECT_STATUS':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageNumber: 1,
          status: action.payload,
        },
      };
    case 'SELECT_VENDOR':
      return {
        ...state,
        filters: {
          ...state.filters,
          pageNumber: 1,
          vendorName: action.payload,
        },
      };
    case USERNAME_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    case CLEAR_PLAN_REMARKS: {
      return {
        ...state,
        planRemarks: {
          pages: 1,
          planRemarksArray: [],
          loading: true,
        },
      };
    }
    case CLEAR_CS_REMARKS: {
      return {
        ...state,
        csRemarks: {
          pages: 1,
          csRemarksArray: [],
          loading: true,
        },
      };
    }

    case 'OPEN_CLOSE_SNACK_BAR': {
      return {
        ...state,
        feedBack: {
          open: action.payload.open,
          severity: action.payload.severity || 'success',
          feedBackMessage: action.payload.feedBackMessage || '',
          anchorVertical: action.payload.anchorVertical || 'top',
          anchorHorizontal: action.payload.anchorHorizontal || 'center',
          autoHideDuration: action.payload.duration || 3000,
        },
      };
    }
    case ADD_DELETE_PRODUCTS_FROM_RATE_AUTO_POPULATION: {
      return {
        ...state,
        addDelProducts: action.payload,
      };
    }
    default:
      return state;
  }
}
